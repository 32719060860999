import * as THREE from 'three'
import Experience from "./Experience";

export default class Renderer{
  constructor(){
    this.experience = new Experience()
    this.canvas = this.experience.canvas
    this.sizes = this.experience.sizes
    this.scene = this.experience.scene
    this.camera = this.experience.camera

    this.setInstance()
  }
  setInstance(){
    this.instance = new THREE.WebGLRenderer({
        canvas: this.canvas,
        antialias: true,
        alpha: true
    })
    this.instance.toneMapping = THREE.CineonToneMapping
    this.instance.toneMappingExposure = 1.75
    this.instance.shadowMap.enabled = true
    this.instance.shadowMap.type = THREE.PCFSoftShadowMap
    // this.instance.setClearColor('#09111B')
    this.instance.setSize(this.sizes.width, this.sizes.height)
    this.instance.setPixelRatio(Math.min(window.devicePixelRatio, 2))
  }

  resize(){
    this.instance.setSize(this.sizes.width, this.sizes.height)
    this.instance.setPixelRatio(Math.min(window.devicePixelRatio, 2))
  }

  update(){
    this.instance.render(this.scene, this.camera.instance)
  }
}
